:root {
  --dark: #121710;
  --light: #fcf8f0;
}

@font-face {
  font-family: BaskOldFace;
  src: url(./assets/baskvill-webfont.woff);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--light);
  border: 2px solid var(--dark);
}

#app {
  font-family: "GillSans-Light", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  color: var(--dark);
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img.top {
  object-position: top;
}

img.bottom {
  object-position: bottom;
}

img.right {
  object-position: right;
}

img.percent {
  object-position: 0 23%;
}

a {
  width: 100%;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}